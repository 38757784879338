import { ToWords } from 'to-words';

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: false,
    ignoreDecimal: true,
  },
});

export const convertNumberToWords = (number) => {
  return toWords.convert(number);
};